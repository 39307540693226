import { useEffect, useState, createRef } from "react";
import { createPluginUI } from "molstar/lib/mol-plugin-ui/react18";
import { DefaultPluginUISpec } from "molstar/lib/mol-plugin-ui/spec";
import { Asset } from "molstar/lib/mol-util/assets";

export default function MolStarWrapper() {
  const parent = createRef();
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    const init = async () => {
      window.molstar = await createPluginUI(parent.current, {
        ...DefaultPluginUISpec(),
        layout: {
          initial: {
            isExpanded: false,
            showControls: false,
          },
        },
      });

      const data = await window.molstar.builders.data.readFile({
        file: Asset.File(selectedFile),
      });

      const trajectory =
        await window.molstar.builders.structure.parseTrajectory(
          data.data,
          "pdb"
        );
      await window.molstar.builders.structure.hierarchy.applyPreset(
        trajectory,
        "default"
      );
    };
    init();

    return () => {
      window.molstar?.dispose();
      window.molstar = undefined;
    };
  }, [parent, selectedFile]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <div className="MolStarWrapper_wrapper">
        <div className="MolStarWrapper_header">
          <p>Antigen</p>
        </div>
        <div
          ref={parent}
          style={{ width: 640, height: 480, position: "relative" }}
        />
        <div className="MolStarWrapper_upload">
          <input
            className="MolStarWrapper_input"
            type="file"
            onChange={(e) => changeHandler(e)}
          />
        </div>
        <div className="MolStarWrapper_description">
          <p>Upload an antigen PDB file to design antibodies against it.</p>
        </div>
        <div className="MolStarWrapper_button">
          <p>Design Antibody</p>
        </div>
      </div>
    </>
  );
}
