import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import logo from "./assets/logo192.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Home_bg">
        <motion.div
          className="Home_wrapper"
          initial={{ opacity: 0 }}
          animate={{ y: -50, opacity: 1 }}
          transition={{ ease: "easeOut", duration: 2 }}
        >
          <img src={logo} alt="Logo" />
          <div className="Home_title">
            <h1>synthetical</h1>
          </div>
          <div className="Home_tagline">
            <p>Design antibodies with one click.</p>
          </div>

          <div className="Home_start" onClick={() => navigate("/designer")}>
            <p>Check out the demo →</p>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Home;
