import { useNavigate } from "react-router-dom";
import MolStarWrapper from "./MolStarWrapper";
import MolStarWrapperAntibody from "./MolStarWrapperAntibody";

import logo from "./assets/logo192.png";

const Designer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Designer_wrapper">
        <div className="Designer_topbar">
          <div className="Designer_logo" onClick={() => navigate("/")}>
            <img src={logo} alt="Logo" />
            <div className="Designer_logo_title">
              <h1>synthetical</h1>
            </div>
          </div>
        </div>
        <div className="Designer_content">
          <MolStarWrapper />
          <MolStarWrapperAntibody />
        </div>
      </div>
    </>
  );
};

export default Designer;
